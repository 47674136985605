.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: @input-focus;
  border: none !important;
}

.ant-select-selector {
  border: none !important;
}
