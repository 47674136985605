@spacing: ~'0' 0px, ~'4' 4px, ~'6' 6px, ~'8' 8px, ~'10' 10px, ~'12' 12px,
  ~'16' 16px, ~'18' 18px, ~'24' 24px, ~'32' 32px, ~'36' 36px, ~'42' 42px,
  ~'48' 48px, ~'64' 64px, ~'80' 80px, ~'96' 96px, ~'120' 120px, ~'auto' auto;

.@{class-prefix}-m-auto {
  margin: auto !important;
}

// Margins
.margin-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-@{prefix}-@{key} {
      margin: @value !important;
    }

    .@{class-prefix}-@{prefix}t-@{key} {
      margin-top: @value !important;
    }

    .@{class-prefix}-@{prefix}b-@{key} {
      margin-bottom: @value !important;
    }

    .@{class-prefix}-@{prefix}y-@{key} {
      margin-bottom: @value !important;
      margin-top: @value !important;
    }

    .@{class-prefix}-@{prefix}x-@{key} {
      margin-right: @value !important;
      margin-left: @value !important;
    }

    // RTL
    html[dir='rtl'] {
      .@{class-prefix}-@{prefix}l-@{key} {
        margin-right: @value !important;
      }

      .@{class-prefix}-@{prefix}r-@{key} {
        margin-left: @value !important;
      }
    }

    // LTR
    html[dir='ltr'] {
      .@{class-prefix}-@{prefix}l-@{key} {
        margin-left: @value !important;
      }

      .@{class-prefix}-@{prefix}r-@{key} {
        margin-right: @value !important;
      }
    }
  }
}

.margin-classes(m, @spacing);

// XL
.margin-classes-xl(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    @media @xl {
      .@{class-prefix}-@{prefix}-xl-@{key} {
        margin: @value !important;
      }

      .@{class-prefix}-@{prefix}t-xl-@{key} {
        margin-top: @value !important;
      }

      .@{class-prefix}-@{prefix}b-xl-@{key} {
        margin-bottom: @value !important;
      }

      .@{class-prefix}-@{prefix}y-xl-@{key} {
        margin-bottom: @value !important;
        margin-top: @value !important;
      }

      .@{class-prefix}-@{prefix}x-xl-@{key} {
        margin-right: @value !important;
        margin-left: @value !important;
      }
    }

    // RTL
    html[dir='rtl'] {
      @media @xl {
        .@{class-prefix}-@{prefix}l-xl-@{key} {
          margin-right: @value !important;
        }

        .@{class-prefix}-@{prefix}r-xl-@{key} {
          margin-left: @value !important;
        }
      }
    }

    // LTR
    html[dir='ltr'] {
      @media @xl {
        .@{class-prefix}-@{prefix}l-xl-@{key} {
          margin-left: @value !important;
        }

        .@{class-prefix}-@{prefix}r-xl-@{key} {
          margin-right: @value !important;
        }
      }
    }
  }
}

.margin-classes-xl(m, @spacing);

// LG
.margin-classes-lg(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    @media @lg {
      .@{class-prefix}-@{prefix}-lg-@{key} {
        margin: @value !important;
      }

      .@{class-prefix}-@{prefix}t-lg-@{key} {
        margin-top: @value !important;
      }

      .@{class-prefix}-@{prefix}b-lg-@{key} {
        margin-bottom: @value !important;
      }

      .@{class-prefix}-@{prefix}y-lg-@{key} {
        margin-bottom: @value !important;
        margin-top: @value !important;
      }

      .@{class-prefix}-@{prefix}x-lg-@{key} {
        margin-right: @value !important;
        margin-left: @value !important;
      }
    }

    // RTL
    html[dir='rtl'] {
      @media @lg {
        .@{class-prefix}-@{prefix}l-lg-@{key} {
          margin-right: @value !important;
        }

        .@{class-prefix}-@{prefix}r-lg-@{key} {
          margin-left: @value !important;
        }
      }
    }

    // LTR
    html[dir='ltr'] {
      @media @lg {
        .@{class-prefix}-@{prefix}l-lg-@{key} {
          margin-left: @value !important;
        }

        .@{class-prefix}-@{prefix}r-lg-@{key} {
          margin-right: @value !important;
        }
      }
    }
  }
}

.margin-classes-lg(m, @spacing);

// MD
.margin-classes-md(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    @media @md {
      .@{class-prefix}-@{prefix}-md-@{key} {
        margin: @value !important;
      }

      .@{class-prefix}-@{prefix}t-md-@{key} {
        margin-top: @value !important;
      }

      .@{class-prefix}-@{prefix}b-md-@{key} {
        margin-bottom: @value !important;
      }

      .@{class-prefix}-@{prefix}y-md-@{key} {
        margin-bottom: @value !important;
        margin-top: @value !important;
      }

      .@{class-prefix}-@{prefix}x-md-@{key} {
        margin-right: @value !important;
        margin-left: @value !important;
      }
    }

    // RTL
    html[dir='rtl'] {
      @media @md {
        .@{class-prefix}-@{prefix}l-md-@{key} {
          margin-right: @value !important;
        }

        .@{class-prefix}-@{prefix}r-md-@{key} {
          margin-left: @value !important;
        }
      }
    }

    // LTR
    html[dir='ltr'] {
      @media @md {
        .@{class-prefix}-@{prefix}l-md-@{key} {
          margin-left: @value !important;
        }

        .@{class-prefix}-@{prefix}r-md-@{key} {
          margin-right: @value !important;
        }
      }
    }
  }
}

.margin-classes-md(m, @spacing);

// SM
.margin-classes-sm(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    @media @sm {
      .@{class-prefix}-@{prefix}-sm-@{key} {
        margin: @value !important;
      }

      .@{class-prefix}-@{prefix}t-sm-@{key} {
        margin-top: @value !important;
      }

      .@{class-prefix}-@{prefix}b-sm-@{key} {
        margin-bottom: @value !important;
      }

      .@{class-prefix}-@{prefix}y-sm-@{key} {
        margin-bottom: @value !important;
        margin-top: @value !important;
      }

      .@{class-prefix}-@{prefix}x-sm-@{key} {
        margin-right: @value !important;
        margin-left: @value !important;
      }
    }

    // RTL
    html[dir='rtl'] {
      @media @sm {
        .@{class-prefix}-@{prefix}l-sm-@{key} {
          margin-right: @value !important;
        }

        .@{class-prefix}-@{prefix}r-sm-@{key} {
          margin-left: @value !important;
        }
      }
    }

    // LTR
    html[dir='ltr'] {
      @media @sm {
        .@{class-prefix}-@{prefix}l-sm-@{key} {
          margin-left: @value !important;
        }

        .@{class-prefix}-@{prefix}r-sm-@{key} {
          margin-right: @value !important;
        }
      }
    }
  }
}

.margin-classes-sm(m, @spacing);

// XS
.margin-classes-xs(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    @media @xs {
      .@{class-prefix}-@{prefix}-xs-@{key} {
        margin: @value !important;
      }

      .@{class-prefix}-@{prefix}t-xs-@{key} {
        margin-top: @value !important;
      }

      .@{class-prefix}-@{prefix}b-xs-@{key} {
        margin-bottom: @value !important;
      }

      .@{class-prefix}-@{prefix}y-xs-@{key} {
        margin-bottom: @value !important;
        margin-top: @value !important;
      }

      .@{class-prefix}-@{prefix}x-sm-@{key} {
        margin-right: @value !important;
        margin-left: @value !important;
      }
    }

    // RTL
    html[dir='rtl'] {
      @media @xs {
        .@{class-prefix}-@{prefix}l-xs-@{key} {
          margin-right: @value !important;
        }

        .@{class-prefix}-@{prefix}r-xs-@{key} {
          margin-left: @value !important;
        }
      }
    }

    // LTR
    html[dir='ltr'] {
      @media @xs {
        .@{class-prefix}-@{prefix}l-xs-@{key} {
          margin-left: @value !important;
        }

        .@{class-prefix}-@{prefix}r-xs-@{key} {
          margin-right: @value !important;
        }
      }
    }
  }
}

.margin-classes-xs(m, @spacing);

//Paddings
.padding-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-@{prefix}-@{key} {
      padding: @value !important;
    }

    .@{class-prefix}-@{prefix}t-@{key} {
      padding-top: @value !important;
    }

    .@{class-prefix}-@{prefix}b-@{key} {
      padding-bottom: @value !important;
    }

    .@{class-prefix}-@{prefix}y-@{key} {
      padding-bottom: @value !important;
      padding-top: @value !important;
    }

    .@{class-prefix}-@{prefix}x-@{key} {
      padding-right: @value !important;
      padding-left: @value !important;
    }

    // RTL
    html[dir='rtl'] {
      .@{class-prefix}-@{prefix}l-@{key} {
        padding-right: @value !important;
      }

      .@{class-prefix}-@{prefix}r-@{key} {
        padding-left: @value !important;
      }
    }

    // LTR
    html[dir='ltr'] {
      .@{class-prefix}-@{prefix}l-@{key} {
        padding-left: @value !important;
      }

      .@{class-prefix}-@{prefix}r-@{key} {
        padding-right: @value !important;
      }
    }
  }
}

.padding-classes(p, @spacing);

// XL
.padding-classes-xl(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    @media @xl {
      .@{class-prefix}-@{prefix}-xl-@{key} {
        padding: @value !important;
      }

      .@{class-prefix}-@{prefix}t-xl-@{key} {
        padding-top: @value !important;
      }

      .@{class-prefix}-@{prefix}b-xl-@{key} {
        padding-bottom: @value !important;
      }

      .@{class-prefix}-@{prefix}y-xl-@{key} {
        padding-bottom: @value !important;
        padding-top: @value !important;
      }

      .@{class-prefix}-@{prefix}x-xl-@{key} {
        padding-right: @value !important;
        padding-left: @value !important;
      }
    }

    // RTL
    html[dir='rtl'] {
      @media @xl {
        .@{class-prefix}-@{prefix}l-xl-@{key} {
          padding-right: @value !important;
        }

        .@{class-prefix}-@{prefix}r-xl-@{key} {
          padding-left: @value !important;
        }
      }
    }

    // LTR
    html[dir='ltr'] {
      @media @xl {
        .@{class-prefix}-@{prefix}l-xl-@{key} {
          padding-left: @value !important;
        }

        .@{class-prefix}-@{prefix}r-xl-@{key} {
          padding-right: @value !important;
        }
      }
    }
  }
}

.padding-classes-xl(p, @spacing);

// LG
.padding-classes-lg(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    @media @lg {
      .@{class-prefix}-@{prefix}-lg-@{key} {
        padding: @value !important;
      }

      .@{class-prefix}-@{prefix}t-lg-@{key} {
        padding-top: @value !important;
      }

      .@{class-prefix}-@{prefix}b-lg-@{key} {
        padding-bottom: @value !important;
      }

      .@{class-prefix}-@{prefix}y-lg-@{key} {
        padding-bottom: @value !important;
        padding-top: @value !important;
      }

      .@{class-prefix}-@{prefix}x-lg-@{key} {
        padding-right: @value !important;
        padding-left: @value !important;
      }
    }

    // RTL
    html[dir='rtl'] {
      @media @lg {
        .@{class-prefix}-@{prefix}l-lg-@{key} {
          padding-right: @value !important;
          padding-left: 0 !important;
        }

        .@{class-prefix}-@{prefix}r-lg-@{key} {
          padding-left: @value !important;
          padding-right: 0 !important;
        }
      }
    }

    // LTR
    html[dir='ltr'] {
      @media @lg {
        .@{class-prefix}-@{prefix}l-lg-@{key} {
          padding-left: @value !important;
        }

        .@{class-prefix}-@{prefix}r-lg-@{key} {
          padding-right: @value !important;
        }
      }
    }
  }
}

.padding-classes-lg(p, @spacing);

// MD
.padding-classes-md(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    @media @md {
      .@{class-prefix}-@{prefix}-md-@{key} {
        padding: @value !important;
      }

      .@{class-prefix}-@{prefix}t-md-@{key} {
        padding-top: @value !important;
      }

      .@{class-prefix}-@{prefix}b-md-@{key} {
        padding-bottom: @value !important;
      }

      .@{class-prefix}-@{prefix}y-md-@{key} {
        padding-bottom: @value !important;
        padding-top: @value !important;
      }

      .@{class-prefix}-@{prefix}x-md-@{key} {
        padding-right: @value !important;
        padding-left: @value !important;
      }
    }

    // RTL
    html[dir='rtl'] {
      @media @md {
        .@{class-prefix}-@{prefix}l-md-@{key} {
          padding-right: @value !important;
          padding-left: 0 !important;
        }

        .@{class-prefix}-@{prefix}r-md-@{key} {
          padding-left: @value !important;
          padding-right: 0 !important;
        }
      }
    }

    // LTR
    html[dir='ltr'] {
      @media @md {
        .@{class-prefix}-@{prefix}l-md-@{key} {
          padding-left: @value !important;
        }

        .@{class-prefix}-@{prefix}r-md-@{key} {
          padding-right: @value !important;
        }
      }
    }
  }
}

.padding-classes-md(p, @spacing);

// SM
.padding-classes-sm(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    @media @sm {
      .@{class-prefix}-@{prefix}-sm-@{key} {
        padding: @value !important;
      }

      .@{class-prefix}-@{prefix}t-sm-@{key} {
        padding-top: @value !important;
      }

      .@{class-prefix}-@{prefix}b-sm-@{key} {
        padding-bottom: @value !important;
      }

      .@{class-prefix}-@{prefix}y-sm-@{key} {
        padding-bottom: @value !important;
        padding-top: @value !important;
      }

      .@{class-prefix}-@{prefix}x-sm-@{key} {
        padding-right: @value !important;
        padding-left: @value !important;
      }
    }

    // RTL
    html[dir='rtl'] {
      @media @sm {
        .@{class-prefix}-@{prefix}l-sm-@{key} {
          padding-right: @value !important;
          padding-left: 0 !important;
        }

        .@{class-prefix}-@{prefix}r-sm-@{key} {
          padding-left: @value !important;
          padding-right: 0 !important;
        }
      }
    }

    // LTR
    html[dir='ltr'] {
      @media @sm {
        .@{class-prefix}-@{prefix}l-sm-@{key} {
          padding-left: @value !important;
        }

        .@{class-prefix}-@{prefix}r-sm-@{key} {
          padding-right: @value !important;
        }
      }
    }
  }
}

.padding-classes-sm(p, @spacing);

// XS
.padding-classes-xs(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    @media @xs {
      .@{class-prefix}-@{prefix}-xs-@{key} {
        padding: @value !important;
      }

      .@{class-prefix}-@{prefix}t-xs-@{key} {
        padding-top: @value !important;
      }

      .@{class-prefix}-@{prefix}b-xs-@{key} {
        padding-bottom: @value !important;
      }

      .@{class-prefix}-@{prefix}y-xs-@{key} {
        padding-bottom: @value !important;
        padding-top: @value !important;
      }

      .@{class-prefix}-@{prefix}x-xs-@{key} {
        padding-right: @value !important;
        padding-left: @value !important;
      }
    }

    // RTL
    html[dir='rtl'] {
      @media @xs {
        .@{class-prefix}-@{prefix}l-xs-@{key} {
          padding-right: @value !important;
          padding-left: 0 !important;
        }

        .@{class-prefix}-@{prefix}r-xs-@{key} {
          padding-left: @value !important;
          padding-right: 0 !important;
        }
      }
    }

    // LTR
    html[dir='ltr'] {
      @media @xs {
        .@{class-prefix}-@{prefix}l-xs-@{key} {
          padding-left: @value !important;
        }

        .@{class-prefix}-@{prefix}r-xs-@{key} {
          padding-right: @value !important;
        }
      }
    }
  }
}

.padding-classes-xs(p, @spacing);
