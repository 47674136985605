.ant-layout-footer {
  padding: 24px 32px;
  background: transparent;
}

@media @sm {
  .ant-layout-footer {
    padding: 18px 16px;
    text-align: center;
  }
}

// RTL
html[dir="rtl"] {
  .ant-layout-footer {
    .@{class-prefix}-text-right {
      text-align: left;
    }
  }
}